import React from 'react'

import { TitleStroke } from '../_titles/_header_title_stroke'

import blogStyles from '../../styles/_components/_blog/_blog_header.module.scss';

export const BlogHeader = ({ _className, blog, useH1 = false, colorStroke = "#FFFFFF", colorText = "#FFFFFF", children }) => {
    return (
        <div className={`${_className} ${blogStyles.oBlogHeader}`}>
            <div className={`o-blog-header_stage ${blogStyles.oBlogHeaderStage}`}>
                <div className={`o-blog-header-info_container ${blogStyles.oBlogHeaderInfo__container}`}>
                    <div className={`o-blog-header_blogtype ${blogStyles.oBlogHeaderBlogtype}`} dangerouslySetInnerHTML={{ __html: blog.node.data.type.document[0].data.name_styled.html }} />
                    <TitleStroke
                        useH1={useH1}
                        textStroke={blog.node.data.title.text}
                        text={blog.node.data.title_filled.text}
                        colorStroke={colorStroke}
                        colorText={colorText}
                        _className={`${blogStyles.oBlogHeaderTitle}`}
                        __classNameSpan={`${blogStyles.oBlogHeaderTitleSpan}`}
                    />
                    { children }
                </div>
            </div>
        </div>
    )
}