import React from 'react'
import { Link } from "gatsby";
import { Picture } from 'react-responsive-picture'

import blogStyles from '../../styles/_components/_blog/_blog_item.module.scss'

import { ButtonSquare } from '../../components/buttons/_button_square'
import { TitleStroke } from '../_titles/_header_title_stroke'
import BlogImageSpot from '../../components/svg/blog/blog_image_spot'
import Moment from 'react-moment';
import 'moment/locale/es'
import ButtonReturn from '../../components/buttons/_btn_return';


export const BlogItemTypes = {
    MAIN: 'oBlogItem__main',
    TO_LEFT: 'oBlogItem__left',
    TO_RIGHT: 'oBlogItem__right',
    SINGLE: 'oBlogItem__single',
}

export const BlogItem = ({ _className, blog, type, single_layout = '', _related = false, _btn_arrow= false, _btn_arrow_text='' }) => {
    // console.log('blog.node.data',blog.node.data.date)
    const short_description = blog.node.data.content.text.length > 120 ? blog.node.data.content.text.substring(0, 120) + ' ...' : blog.node.data.content.text;
    const single_layout_class = type === BlogItemTypes.SINGLE ? blogStyles[`oBlogItem__single${single_layout}`] : '';
    const date = blog.node.data.date;

    return (
        <div className={`${_className ? _className : ''} ${_className ? _className + '-' + single_layout : ''} ${blogStyles.oBlogItem} ${blogStyles[type || BlogItemTypes.SINGLE]} ${single_layout_class}`}>
            {/* <div className={blogStyles.oBlogItemSectionHeader}>
                <div className={blogStyles.oBlogItemSectionHeader__bg}/>
                <p className={blogStyles.oBlogItemSectionHeader__text}>{blog.node.data.type.document[0].data.name.text}</p>
            </div> */}
            <Link to={`/blog/${blog.node.data.type.document[0].uid}/${blog.node.uid}`} className={`o-blog-item_stage ${blogStyles.oBlogItemStage}`}>
                <div className={`o-blog-item_img_container ${blogStyles.oBlogItemImg__container}`} >
                    <div className={blogStyles.oBlogItemImg__inner} >
                        <div className={`o-blog-item_img_spot ${blogStyles.oBlogItemImg__spot1}`}>
                            <BlogImageSpot />
                        </div>
                        <Picture
                            sources={[
                                {
                                    srcSet: blog.node.data.menu_image.url
                                }
                            ]}
                        />
                        <div className={`o-blog-item_img_spot ${blogStyles.oBlogItemImg__spot2}`}>
                            <BlogImageSpot />
                        </div>
                    </div>
                </div>
                <div className={`o-blog-item_info_container ${blogStyles.oBlogItemInfo__container}`}>
                    <div className="o-blog-item_info_inner">
                        <div className={`o-blog-item_blogtype ${blogStyles.oBlogItemBlogtype}`} dangerouslySetInnerHTML={{ __html: blog.node.data.type.document[0].data.name_styled.html }} />
                        <TitleStroke
                            textStroke={blog.node.data.title.text}
                            text={blog.node.data.title_filled.text}
                            colorStroke="#0C77D9"
                            colorText="#001f5b"
                            isStroke={false}
                            _className={blogStyles.oBlogItemTitleStroke}
                        />
                        <Moment locale="es" format="LL" className={blogStyles.oBlogItem_date} element="span">{`${date}`}</Moment>
                        <p className={`o-blog-item_content ${blogStyles.oBlogItemContent}`}>{short_description}</p>
                    </div>
                    { _btn_arrow ?
                        <ButtonReturn _to={`/blog/${blog.node.data.type.document[0].uid}/${blog.node.uid}`} text={_btn_arrow_text} />
                        :
                        <div className={`o-blog-item_button_container ${blogStyles.oBlogItem__button_container}`}>
                            <ButtonSquare
                                _slug={blog.node.uid}
                                _bg_color="#febe23"
                                _text='Leer Más'
                                _to={`/blog/${blog.node.data.type.document[0].uid}/${blog.node.uid}`}
                                _className="blog_more_button_item"
                            />
                        </div>
                    }
                </div>
            </Link>
        </div>
    );
}