export class GetBodyItem{
    
    constructor()
    {

    }
    get_item(_model,_search)
    {
        let body =  _model.data.body;
        if(body===null) return null;
        let found = false;
        let item_count = 0;    
        
        while (!found && item_count<body.length)
        {
            let item = body[item_count];
            if(item.__typename===_search)
            {
                found = true;
                return item;            
            }
            item_count++;
        }
        return found;
    }
    get_index_item(_model,_search)
    {
        let body =  _model.data.body1;
        if(body===null) return null;
        let found = false;
        let item_count = 0;    
        
        while (!found && item_count<body.length)
        {
            let item = body[item_count];
            if(item.__typename===_search)
            {
                found = true;
                return item;            
            }
            item_count++;
        }
        return found;
    }
    
}

export default GetBodyItem