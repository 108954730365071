import React from 'react';

export const MenuSlide=({children,_className})=>{
    return (
        <div className={`o-menu_slide_mask ${_className}`}>
            <div className="o-menu_slide_container">    
                {children}  
            </div>
        </div>
    )
}