import { useStaticQuery, graphql } from "gatsby"

export const usePrismBlogsByFamily = (_item) => {
  const families = [];
  const prismic = useStaticQuery(graphql`
    {
      allPrismicBlog {
        edges {
          node {
            uid
            type
            first_publication_date
            data {
              date
              type {
                slug
                document {
                  data {
                    name {
                      text
                    }
                    name_styled{
                      html
                    }
                  }
                }
              }
              title {
                text
              }
              title_filled {
                text
              }
              content {
                html
                text
              }
              type {
                link_type
                ... on PrismicBlogDataType {
                  document {
                    uid
                    type
                    data {
                      name {
                        html
                        text
                      }
                    }
                  }
                }
              }
              body {
                __typename
                ... on PrismicBlogBodyCopy {
                  primary {
                    title1 {
                      html
                      text
                    }
                    title_filled1{
                      text
                      html
                    }
                  }
                }
                __typename
                ... on PrismicBlogBodyTexto {
                  items {
                    description {
                      html
                      text
                    }
                  }
                }
              }
              header_image {
                url
                alt
                xs {
                  alt
                  url
                }
                sm {
                  alt
                  url
                }
                md {
                  alt
                  url
                }
                lg {
                  alt
                  url
                }
              }
              menu_image {
                url
                xs {
                  url
                }
                sm {
                  url
                }
                md {
                  url
                }
              }
            }
          }
        }
      }
    }     
  `);

  let next = 0;
  let prev = 0;

  prismic.allPrismicBlog.edges.map((element, index) => {
    if (element.node.uid !== _item.node.uid) {
      if (families[element.node.data.type.document[0].uid] === undefined) {
        families[element.node.data.type.document[0].uid] = {
          name: element.node.data.type.document[0].data.name.text,
          items: []
        }
      }
      families[element.node.data.type.document[0].uid].items.push(element)
    } else {
      if (families[element.node.data.type.document[0].uid] === undefined) {
        families[element.node.data.type.document[0].uid] = {
          name: element.node.data.type.document[0].data.name.text,
          items: []
        }
      }
      
      families[element.node.data.type.document[0].uid].items.push(element)
      next = families[_item.node.data.type.document[0].uid] ? families[_item.node.data.type.document[0].uid].items.length : 0
    }
  });

  next = next >= families[_item.node.data.type.document[0].uid].items.length ? 0 : next
  prev = next - 1 < 0 ? families[_item.node.data.type.document[0].uid].items.length - 1 : next - 1

  return { families, next, prev };
}

