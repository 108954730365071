import React from 'react'

const Facebook = props => (
  
  <svg viewBox="0 0 50 50" {...props}>
    <path d="M25 0C11.2 0 0 11.3 0 25.2c0 12.6 9.1 23 21.1 24.8V32.4h-6.3v-7.3h6.3v-5.5c0-6.3 3.7-9.8 9.4-9.8 2.7 0 5.6.5 5.6.5v6.2H33c-3.1 0-4.1 1.9-4.1 3.9v4.7h6.9l-1.1 7.3h-5.8V50C40.9 48.1 50 37.7 50 25.2 50 11.3 38.8 0 25 0z" />
    <defs>

    {props.color_start==='' ?
      <linearGradient id={`${props.section}_fb_icon`}>
        <stop offset="5%" stopColor="#f95360" />
        <stop offset="95%" stopColor="#f84f5f" />
      </linearGradient>
      :
      <linearGradient id={`${props.section}_fb_icon`}>
        <stop offset="5%" stopColor={props.color_start} />
        <stop offset="95%" stopColor={props.color_end} />
      </linearGradient>
    }
    </defs>
    
  </svg>
)

export default Facebook