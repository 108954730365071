import { useStaticQuery, graphql } from "gatsby"

export const usePrismBlogs = () => {

  const prismic = useStaticQuery(graphql`
    {
      allPrismicBlog(sort:{
          fields: [data___date]
          order: DESC
        }){
        edges {
          node {
            uid
            type
            first_publication_date
            data {
              date
              type {
                slug
                document {
                  data {
                    name {
                      text
                    }
                    name_styled{
                      html
                    }
                  }
                }
              }
              title {
                text
              }
              title_filled {
                text
              }
              content {
                html
                text
              }
              type {
                link_type
                ... on PrismicBlogDataType {
                  document {
                    uid
                    type
                    data {
                      name {
                        html
                        text
                      }
                    }
                  }
                }
              }
              body {
                __typename
                ... on PrismicBlogBodyCopy {
                  primary {
                    title1 {
                      html
                      text
                    }
                    title_filled1{
                      text
                      html
                    }
                  }
                }
                __typename
                ... on PrismicBlogBodyTexto {
                  items {
                    description {
                      html
                      text
                    }
                  }
                }
              }
              header_image {
                url
                alt
                xs {
                  alt
                  url
                }
                sm {
                  alt
                  url
                }
                md {
                  alt
                  url
                }
                lg {
                  alt
                  url
                }
              }
              menu_image {
                url
                xs {
                  url
                }
                sm {
                  url
                }
                md {
                  url
                }
              }
            }
          }
        }
      }
    }     
  `);

  return prismic.allPrismicBlog


}