import React from 'react'
import Loadable from "@loadable/component"

import { BlogItem, BlogItemTypes } from '../../components/_blog/_blog_item';

const AwesomeSwiper = Loadable(() => import("react-awesome-swiper"));

const config = {
  // width: 800,
  slidesPerView: 2,
  loop: true,
  autoplay: {
    delay: 300000,
    stopOnLastSlide: false,
    disableOnInteraction: true,
  },
  // Disable preloading of all images
  preloadImages: false,
  // Enable lazy loading
  lazy: true,
  speed: 500,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    bulletElement: 'li',
    hideOnClick: true,
    clickable: true,
  },
  breakpoints: {
    1023: {
      slidesPerView: 1,
    }
  },
  on: {
    slideChange: function () {
      console.log(this.activeIndex);
    },
  },
};

class BlogDetailCarousel extends React.Component {
  swiperRef = null

  create_dynamics_items(_items) {
    return _items.map((_item, _index) => {
      return (
        <div key={`carousel-${_item.node.uid}`} className="swiper-slide blog_carrousel_item">
          <BlogItem blog={_item} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout=''></BlogItem>
        </div>
      )
    });
  };

  render() {
    if (this.props.datablogs !== undefined) {
      return (
        <AwesomeSwiper ref={this.swiperRef} config={config}>
          <div className="swiper-wrapper">
            {this.create_dynamics_items(this.props.datablogs)}
          </div>
          <div className="swiper-pagination"></div>
        </AwesomeSwiper>
      );
    }
    else {
      return (<></>)
    }

  }
}

export default BlogDetailCarousel