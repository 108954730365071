import React from 'react';
import { Link } from "gatsby";
import SvgArrowReturn from '../../components/svg/commons/arrow_return'
import '../../styles/_components/_buttons/_button_return.scss';

const ButtonReturn = ({ _to, text, className }) => {
    return (
        <Link className={`blog_detail_item__return ${className}`} to={ _to }>
            <SvgArrowReturn/>
            <p>{ text }</p>
        </Link>
    )
}
export default ButtonReturn;