import React, { Fragment, useEffect, useState } from "react"
import { Picture } from 'react-responsive-picture';

import GetBodyItem from '../components/_helpers/_get_body_item'

import { usePrismBlogsByFamily } from '../hooks/use_prism-blogs-by-family';
import { usePrismBlogs } from '../hooks/use_prism-blogs'

import Layout from '../components/layout';
import Head from '../components/head'
import { BlogItem, BlogItemTypes } from '../components/_blog/_blog_item';
import { ShareBlog } from '../components/_share/_share_blog';
import { TitleStroke } from "../components/_titles/_header_title_stroke";
import { MenuSlide } from '../components/_menu/_menu_slide';
import { BlogHeader } from "../components/_blog/_blog_header";
import BlogDetailCarousel from '../content/blog/blog_detail_carousel';
import ButtonReturn from '../components/buttons/_btn_return';

import "../styles/pages/blog/blog.scss"

const BlogDetail = (props) => {

    const _item = props.pageContext.item;

    const prismic_blogs = usePrismBlogsByFamily(_item)
    const allBlogs = usePrismBlogs();

    const blogs_by_family = prismic_blogs.families
    const [nextBlogIndex, setNextBlogIndex] = useState(null);
    const [prevBlogIndex, setPrevBlogIndex] = useState(null);

    const blogs = blogs_by_family[_item.node.data.type.document[0].uid].items;

    const metasData = new GetBodyItem().get_item(_item.node, "PrismicBlogBodyMetas");
    const metas = {
        title: 'Cinsa Blog',
        description: 'Cinsa Blog Descripción',
        image: ''
    }
    if (metasData) {
        metas.title = metasData.primary.title.text
        metas.description = metasData.primary.description.text
        metas.image = metasData.primary.image_share.url
    }

    function set_date_format(_date) {
        if (_date) {
            const date_array = _date.split('-');
            const months = ['', 'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'];
            return date_array[2] + ' de ' + months[parseInt(date_array[1])] + ' ' + date_array[0];
        } else {
            return '';
        }

    }

    function get_detail() {
        return (
            <div className="blog_detail_container">
                <p className="blog_detail_date">Ciudad de México, {set_date_format(_item.node.data.date)}</p>
                <div className="blog_detail_content_top blog_detail_content" dangerouslySetInnerHTML={{ __html: _item.node.data.content.html }}></div>
                {
                    _item.node.data.body ?
                        _item.node.data.body.map((_block, key) => {
                            switch (_block.__typename) {
                                case "PrismicBlogBodyCopy":
                                    return (
                                        <Fragment key={`blog_detail_copy_${key}`}>
                                            {
                                                _block.primary &&
                                                _block.primary.title1 && _block.primary.title_filled1 &&
                                                <TitleStroke
                                                    useH1={false}
                                                    textStroke={_block.primary.title1.text}
                                                    text={_block.primary.title_filled1.text}
                                                    colorStroke="#3677D2"
                                                    colorText="#001f5b"
                                                />
                                            }
                                        </Fragment>
                                    )
                                    break;
                                case "PrismicBlogBodyTexto":
                                    return (
                                        <Fragment key={`blog_detail_text_${key}`}>
                                            <div className="blog_detail_content_bottom blog_detail_content" dangerouslySetInnerHTML={{ __html: _block.items[0].description.html }} />
                                        </Fragment>
                                    )
                                    break;
                            }
                        })
                        :
                        <Fragment />
                }
            </div>
        );
    }

    useEffect(() => {
        allBlogs.edges.forEach((element, index) => {
            if(element.node.uid === props.pageContext.item.node.uid) {


                if(index === 0) {
                    setPrevBlogIndex(allBlogs.edges[allBlogs.edges.length - 1])
                } else {
                    setPrevBlogIndex(allBlogs.edges[index-1])
                }
                
                if(index === allBlogs.edges.length - 1) {
                    setNextBlogIndex(allBlogs.edges[0])
                    return
                }

                setNextBlogIndex(allBlogs.edges[index+1])
            }
        });
    },[])

    return (
        <Layout>
            {/* <Head
                title={metas.title}
                pathname={`/blog/${_item.node.data.type.document[0].uid}/${_item.node.uid}`}
                description={metas.description}
                image={metas.image}
            /> */}
            {/* <BlogSearch></BlogSearch> */}
            <div className="blog-container blog-container--on-detail">
                <BlogHeader blog={_item} useH1={true} _className="blog-container-header">
                    <ButtonReturn _to='/blog' text='volver' className="md" />
                </BlogHeader>
                <div className="blog_header_stage">
                    <div className="blog_carousel">
                        <Picture sources = {[
                            {
                                srcSet: _item.node.data.header_image.xs.url,
                                media: "(max-width: 767px)",
                            },
                            {
                                srcSet: _item.node.data.header_image.sm.url,
                                media: "(max-width: 1023px)",
                            },
                            {
                                srcSet: _item.node.data.header_image.md.url,
                                media: "(max-width: 1280px)",
                            },
                            {
                                srcSet: _item.node.data.header_image.lg.url,
                                media: "(max-width: 1919px)",
                            },
                            {
                                srcSet: _item.node.data.header_image.url,
                                media: "(min-width: 1920px)",
                            },
                            ]}
                        />
                    </div>
                    <ButtonReturn _to='/blog' text='volver' />
                </div>
                <div className="blog_body">
                    <div className='blog_body_main'>
                        <div className="blog_share">
                            <ButtonReturn _to='/blog' text='volver' />
                            <ShareBlog _fb_to={`/blog/${_item.node.data.type.document[0].uid}/${_item.node.uid}`} _tw_to={`/blog/${_item.node.data.type.document[0].uid}/${_item.node.uid}`} _section='blog_detail' _color_start='#febe23' _color_end='#febe23'></ShareBlog>
                        </div>
                        {get_detail()}
                        <div className="blog_next_prev">
                            {
                                nextBlogIndex && <BlogItem blog={prevBlogIndex} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='' _btn_arrow={true} _btn_arrow_text='Anterior' ></BlogItem>
                            }
                            {
                                prevBlogIndex && <BlogItem blog={nextBlogIndex} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='' _btn_arrow={true} _btn_arrow_text='Siguiente' ></BlogItem>
                            }
                            {/* <BlogItem blog={blogs[prevBlogIndex]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='' _btn_arrow={true} _btn_arrow_text='Anterior' ></BlogItem>
                            <BlogItem blog={blogs[nextBlogIndex]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout='' _btn_arrow={true} _btn_arrow_text='Siguiente' ></BlogItem> */}
                        </div>
                    </div>
                    <div className='blog_body_related'>
                        <p className='blog_body_related_title'> <span>Notas</span> Relacionadas</p>
                        <MenuSlide _className="blog_body_related_slider">
                            {
                                blogs[0] !== undefined &&
                                <BlogItem blog={blogs[0]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout=''></BlogItem>
                            }

                            {
                                blogs[1] !== undefined &&
                                <BlogItem blog={blogs[1]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout=''></BlogItem>
                            }

                            {
                                blogs[2] !== undefined &&
                                <BlogItem blog={blogs[2]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout=''></BlogItem>
                            }
                            {
                                blogs[3] !== undefined &&
                                <BlogItem blog={blogs[3]} _className="blog_body_related_slider_item" type={BlogItemTypes.SINGLE} single_layout=''></BlogItem>
                            }
                        </MenuSlide>
                    </div>
                    <div className='blog_body_related_carousel'>
                        {/* <p className='blog_body_related_title'>Noticias Relacionadas</p> */}
                        <BlogDetailCarousel datablogs={blogs} />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default BlogDetail
